import axios from 'axios';

export default {
  async getAddress(cep) {
    return axios
      .get(`https://viacep.com.br/ws/${cep}/json`, {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization'];
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },

  async getCities() {
    return axios
      .get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios`, {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization'];
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
};
