<template>
  <div class="pt-36 pb-20 bg-indigo-100 grid grid-cols-12">
    <div
      v-if="showAlert"
      class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full sm:p-6"
      >
        <div>
          <div
            class="absolute top-3 cursor-pointer text-sm right-5"
            @click="showAlert = false"
          >
            Fechar
          </div>
          <div
            class="mx-auto text-center rounded-full bg-red-200 w-10 h-10 flex items-center"
          >
            <svg
              class="h-6 w-6 text-red-600 mx-auto"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Depois de criado, você não vai mais conseguir editar o documento
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-800" v-if="showAlert">
                Você tem certeza que as informações estão corretas?
              </p>
              <p class="text-xs text-left my-6 text-gray-700">
                Como a easy termos oferece compras únicas com custo baixo, no
                momento não podemos oferecer edição de políticas. Se você não
                tiver certeza se deve incluir determinados detalhes em sua
                política ao preencher o formulário, recomendamos incluí-los de
                qualquer maneira. Isso evita que você tenha que voltar e fazer
                outra compra se precisar alterar alguma coisa.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <span class="flex gap-6 w-full rounded-md shadow-sm">
            <button
              @click="validateFormTerms()"
              type="button"
              v-if="showAlert === true"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-et-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-et-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Cadastrar documento
            </button>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="showModalPlanoAvancado"
      class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full sm:p-6"
      >
        <div>
          <div
            class="absolute top-3 cursor-pointer text-sm right-5"
            @click="showModalPlanoAvancado = false"
          >
            Fechar
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Você vai ser redirecionado para a tela de pagamento
            </h3>
            <div class="mt-2">
              <p
                class="text-sm leading-5 text-gray-800"
                v-if="showModalPlanoAvancado"
              >
                Após a compra você vai conseguir baixar os relatórios e guias,
                além de ter atualizações gratuitas no futuro.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <span class="flex gap-6 w-full rounded-md shadow-sm">
            <button
              @click="handleSelectPlan(5)"
              type="button"
              v-if="showModalPlanoAvancado === true"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-et-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-et-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Ir para o pagamento
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="hidden md:block col-span-6">
      <div class="mr-0 ml-auto pr-10 mr-6" style="max-width: 600px !important">
        <h1
          class="mt-4 md:max-w-3xl w-full text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
        >
          Termos de Uso
        </h1>

        <p class="leading-2 text-gray-600 my-4 text-sm">
          Não sabe se precisa dos documentos?
          <router-link
            to="/artigos/o-que-e-lgpd-e-por-que-sua-empresa-deve-se-adequar"
            class="text-sm underline"
            >Ler sobre a importância de estar de acordo com a LGPD</router-link
          >
        </p>
        <div class="mb-4 flex flex-wrap text-sm font-medium text-black">
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-et-500 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Política de Privacidade
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-et-500 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Versão HTML
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-et-500 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Versão em Texto
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-et-500 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Termos de Uso
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-et-500 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Preparado para a LGPD
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-red-700 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            Relatórios de Ocorrências
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-red-700 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            Guia de Utilização
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-red-700 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            Embed de Documentos
          </p>
          <p class="flex items-center mr-3 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-5 h-5 text-red-700 mr-2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            Customização Visual
          </p>
        </div>

        <div class="">
          <div
            @click="showModalPlanoAvancado = true"
            v-if="!hasAccessReports"
            class="btn hover:scale-105 bg-et-500 duration-150 transition hover:bg-et-600 mt-6 text-white rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
          >
            Não sabe como utilizar? Documentos extras e relatórios por apenas R$
            40,00
          </div>
        </div>
      </div>
    </div>
    <div
      class="relative px-10 py-5 bg-white rounded-lg shadow-2xl col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-5 ml-0 mr-auto"
      style="max-width: 600px"
    >
      <div id="geradorForm">
        <form
          class="flex grid grid-cols-12 gap-x-6 gap-y-3 w-full form-generator validate"
          autocomplete="foo"
          @submit.prevent
        >
          <label
            class="text-sm col-span-6 font-medium"
            for="razao_social"
            :class="{ 'is-filled': formData.nm_rz_social }"
          >
            <span class="block font-medium text-black">Nome da Empresa</span>

            <input
              v-model="formData.nm_rz_social"
              id="razao_social"
              type="text"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>

          <label
            class="text-sm col-span-6 font-medium"
            for="nome_fantasia"
            :class="{ 'is-filled': formData.nm_fantasia }"
          >
            <span class="block font-medium text-black">Nome Fantasia</span>

            <input
              v-model="formData.nm_fantasia"
              id="nome_fantasia"
              type="text"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.cd_cnpj }"
          >
            <span class="block font-medium text-black">CNPJ</span>

            <TheMask
              v-model="formData.cd_cnpj"
              type="text"
              name="cnpj"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              :mask="['##.###.###/####-##']"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.cd_endereco_cep }"
          >
            <span class="block font-medium text-black">CEP</span>

            <TheMask
              v-model="formData.cd_endereco_cep"
              type="text"
              name="cep"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              :mask="['##.###-###']"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_endereco_cidade }"
          >
            <span class="block font-medium text-black">Cidade</span>

            <input
              v-model="formData.nm_endereco_cidade"
              type="text"
              name="cidade"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_endereco_bairro }"
          >
            <span class="block font-medium text-black">Bairro</span>

            <input
              v-model="formData.nm_endereco_bairro"
              type="text"
              name="bairro"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_endereco_logradouro }"
          >
            <span class="block font-medium text-black">Logradouro</span>

            <input
              v-model="formData.nm_endereco_logradouro"
              type="text"
              name="logradouro"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.cd_endereco_numero }"
          >
            <span class="block font-medium text-black">Número</span>

            <input
              v-model="formData.cd_endereco_numero"
              type="text"
              name="endereco_numero"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_endereco_complemento }"
          >
            <span class="block font-medium text-black">Complemento</span>

            <input
              v-model="formData.nm_endereco_complemento"
              type="text"
              name="complemento"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_site }"
          >
            <span class="block font-medium text-black">Nome do sistema</span>

            <input
              v-model="formData.nm_site"
              type="text"
              name="nm_site"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-12 font-medium"
            :class="{ 'is-filled': formData.ds_link_pp }"
            ><span
              >Link Politicas de Privacidade
              <small class="text-gray-500">Opcional</small></span
            >
            <input
              v-model="formData.ds_link_pp"
              type="url"
              name="link_politicas"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-12 font-medium"
            :class="{ 'is-filled': formData.ds_email_contato }"
          >
            <span class="block font-medium text-black">Email para contato</span>

            <input
              v-model="formData.ds_email_contato"
              type="email"
              name="email_contato"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label class="text-sm col-span-12 font-medium">
            <textarea
              v-model="formData.ds_servicos"
              type="text"
              class="required-depends-on w-100 p-3 border"
              placeholder="Descreva os serviços prestados"
              style="
                width: 100%;
                min-height: 52px;
                max-height: 150px;
                height: 52px;
              "
            />
          </label>
          <div class="col-span-12 pb-3"></div>
          <h3
            class="text-lg font-medium col-span-12 py-6 pb-0 border-t-2 border-gray-200"
          >
            Customizar política da empresa
          </h3>
          <fieldset class="col-span-12">
            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                  checked
                  disabled
                />
                <span class="checkbox"></span>
                <span class="text-black">
                  <strong>Serviço Contrato</strong>
                  Descreva o serviço contratado
                </span>
              </label>
              <div
                class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
              >
                <div class="sub">
                  <ul class="grid grid-cols-2">
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_servico_website"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black"
                          >Website</span
                        >
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_servico_ecommerce"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black"
                          >Ecommerce</span
                        >
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_servico_sass_sub"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black"
                          >SaaS / Subscription</span
                        >
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_servico_app"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black"
                          >Aplicativo para dispositivo móvel</span
                        >
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_servico_outro"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Outro</span>
                      </label>
                      <div :class="{ 'u-hide': !formData.is_servico_outro }">
                        <input
                          v-model="formData.ds_servico_outro"
                          type="text"
                          name="Serviço outro"
                          class="form-input mt-3"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-span-12 my-3"></div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_vendido_produtos"
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong>Tem venda de produtos</strong>
                  Os usuários podem comprar produtos em sua plataforma?
                </span>
              </label>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_aceito_pagamento"
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong>Aceita pagamentos</strong>
                  Informe quais pagamentos o seu sistema aceita.
                </span>
              </label>
              <div
                class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                :class="{ 'u-hide': !formData.is_aceito_pagamento }"
              >
                <div class="sub">
                  <p class="block font-medium text-black">
                    Quais formas de pagamento o seu sistema aceita ?
                  </p>

                  <ul>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_cartao_debito"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Cartão de Débito
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_cartao_credito"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Cartão de Crédito
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_boleto"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Boleto</span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_picpay"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Picpay</span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_paypal"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Paypal</span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_aceito_outros"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Outro</span>
                      </label>
                      <div :class="{ 'u-hide': !formData.is_aceito_outros }">
                        <input
                          v-model="formData.ds_outros_pagamentos"
                          type="text"
                          name="Pagamentos outro"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_permitido_criar_conta"
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                  value="cookies"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong>Usuário podem criar conta</strong>
                  Selecione caso os usuários possam criar conta no sistema.
                </span>
              </label>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_usuario_notificado_att"
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                  value="cookies"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong
                    >Usuário são notificado através do e-mail sobre
                    atualizações</strong
                  >
                  Selecione caso os usuários sejam notificados via e-mail quando
                  ocorrer atualizações nos termos de uso
                </span>
              </label>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_permitido_vincular_conta"
                  class="toggle form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  type="checkbox"
                  name="options[]"
                  value="cookies"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong>
                    Usuário podem vincular conta com perfis em mídias sociais
                  </strong>
                  Selecione caso os usuários possam vincular suas contas com
                  perfis em mídias sociais
                </span>
              </label>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_permitido_avaliacoes"
                  class="toggle controlador-dados form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  name="options[]"
                  type="checkbox"
                  value="controlador-dados"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong>
                    Os usuários podem enviar avaliações dos produtos
                  </strong>
                  Selecione caso tenha a possibilidade dos usuários enviarem
                  avaliações no sistema
                </span>
              </label>
            </div>

            <div class="col-span-12 my-3">
              <label class="text-sm text-gray-800 flex items-center mt-0 pt-0">
                <input
                  v-model="formData.is_atividades_proibidas"
                  class="toggle controlador-dados form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                  name="options[]"
                  type="checkbox"
                  value="controlador-dados"
                />
                <span class="checkbox"></span>
                <span class="block font-medium text-black">
                  <strong> Atividades Proibidas </strong>
                  Em nosso TOU já proibimos os usuários de utilizar os serviços
                  oferecidos na plataforma para fins ilícitos, não lesar os
                  direitos e interesses de sua empresa, de outros usuários e/ou
                  terceiros, ou de qualquer forma prejudicar, desativar ou
                  sobrecarregar os serviços.
                </span>
              </label>
              <div
                class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                :class="{ 'u-hide': !formData.is_atividades_proibidas }"
              >
                <div class="sub">
                  <ul>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_proibido_alterar_violar"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Alterar ou violar, de qualquer forma, os sistemas de
                          autenticação, verificação de identidade e/ou de
                          segurança dos serviços, redes ou cadastros de usuários
                          e/ou administradores.
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="
                            formData.is_proibido_promover_cortes_comunicacao
                          "
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Promover, de forma proposital, interrupções, mudanças
                          ou cortes nas comunicações do site e/ou nos serviços
                          disponibilizados, bem como, efetuar ataques
                          cibernéticos ou similares;
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="
                            formData.is_proibido_monitorar_transimitir_virus
                          "
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Efetuar qualquer tipo de monitoramento que envolva a
                          interceptação de informações que não se destinem ao
                          usuário, remeter ou transmitir arquivos com vírus.
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_proibido_induzir_error"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Utilizar qualquer programa de computador ou qualquer
                          outra forma que induza a erro, com o objetivo de obter
                          vantagens patrimoniais ou comerciais em favor do
                          usuário ou de terceiros
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_proibido_impedir_acesso_site"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[third-party-services][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">
                          Efetuar ações que limitem, deneguem ou vetem qualquer
                          usuário de acessar o site e utilizar os serviços
                        </span>
                      </label>
                    </li>
                    <li class="my-2">
                      <label
                        class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                      >
                        <input
                          v-model="formData.is_proibido_outros"
                          class="form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                          type="checkbox"
                          name="dictionary[collects-personal-info][]"
                        />
                        <span class="checkbox"></span>
                        <span class="block font-medium text-black">Outro</span>
                      </label>
                      <div
                        :class="{
                          'u-hide': !formData.is_proibido_outros,
                        }"
                      >
                        <input
                          v-model="formData.ds_proibido_outros"
                          type="text"
                          name="Pagamentos outro"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-span-12 my-3">
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_foro"
                    class="toggle controlador-dados form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                    name="options[]"
                    type="checkbox"
                    value="controlador-dados"
                  />
                  <span class="checkbox"></span>
                  <span class="block font-medium text-black">
                    <strong>
                      Cidades onde os usuários podem resolver divergências
                    </strong>
                    Onde as controvérsias ou divergências entre você e seus
                    usuários serão resolvidas?
                  </span>
                </label>
                <div
                  class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                  :class="{ 'u-hide': !formData.is_foro }"
                >
                  <div class="sub">
                    <div class="col-span-12 my-3">
                      <Multiselect
                        v-model="formData.li_foro_cidades"
                        :options="cities"
                        multiple
                        tag-position="bottom"
                        :close-on-select="false"
                        placeholder="Selecione as cidades"
                        selectLabel="Clique para selecionar"
                        deselectLabel="Clique para remover a seleção"
                        selectedLabel="Selecionado"
                        noResult="Não foi encontrado resultados para a sua pesquisa"
                      ></Multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-span-12 my-3"></div>

              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_nova_clausula"
                    class="toggle dpo-checkbox form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
                    name="options[]"
                    type="checkbox"
                    value="dpo"
                  />
                  <span class="checkbox"></span>
                  <span class="block font-medium text-black">
                    <strong> Adicionar sua própria cláusula nos termos </strong>
                    Selecione caso deseja adicionar uma cláusula nos termos de
                    uso
                  </span>
                </label>
                <div
                  class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                  :class="{ 'u-hide': !formData.is_nova_clausula }"
                >
                  <div class="sub">
                    <div class="col-span-12 my-3">
                      <label
                        class="text-sm col-span-12 font-medium"
                        for="nome_nova_clausula"
                        :class="{ 'is-filled': formData.nm_nova_clausula }"
                      >
                        <input
                          v-model="formData.nm_nova_clausula"
                          id="nome_nova_clausula"
                          type="text"
                          class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
                          placeholder="Título da clausula"
                          maxlength="100"
                        />
                      </label>

                      <textarea
                        v-model="formData.ds_nova_clausula"
                        type="text"
                        class="required-depends-on w-100 text-xs p-3 mt-2"
                        placeholder="Escreva sua cláusula"
                        style="
                          width: 100%;
                          min-height: 100px;
                          max-height: 200px;
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="text-xs text-gray-500 col-span-12">
            O software, os materiais e a assistência fornecidos pela Easy Termos
            têm como único objetivo auxiliar os usuários no cumprimento de seus
            requisitos legais. Em particular, os modelos fornecidos são gerados
            automaticamente, mas cada palavra de nosso modelo foi escrita e
            continuamente revisada por uma equipe jurídica qualificada. No
            entanto, como pode ser facilmente compreendido, nada pode substituir
            uma consultoria jurídica profissional na elaboração da sua política
            de privacidade, política de cookies ou de qualquer outro documento
            legal ou procedimento de compliance.
          </div>

          <label
            class="text-sm text-gray-800 flex items-center mt-0 pt-0 col-span-12 mb-6"
          >
            <input
              class="toggle controlador-dados form-checkbox h-6 w-6 rounded text-et-500 shadow mr-4 pt-0"
              name="options[]"
              type="checkbox"
              v-model="formData.is_concordo_isencao_easy"
              required
            />
            <span class="checkbox"></span>
            <span class="block font-medium text-black">
              <strong> Eu concordo com o aviso legal da Easy Termos </strong>
            </span>
          </label>

          <button
            @click="showAlert = true"
            type="submit"
            class="bg-et-500 col-span-12 hover:bg-indigo-500 transition duration-300 text-white font-bold px-4 rounded w-full py-5"
          >
            {{ buttonText }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { functions } from '@/utils/functions';
import AddressService from '@/services/addressService';
import router from '@/router';

import { TheMask } from 'vue-the-mask';

import Datepicker from 'vuejs-datepicker';
import ptBR from 'vuejs-datepicker/dist/locale/translations/pt-BR';

import Multiselect from 'vue-multiselect';
import stripeService from '@/services/stripeService';

export default {
  name: 'FormPoliticaPrivacidade',
  status: 'prototype',
  release: '1.0.0',
  mixins: [functions],
  components: {
    TheMask,
    Datepicker,
    Multiselect,
  },
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Atualizar seus Termos',
    },
  },
  inject: ['showAlert'],
  data() {
    return {
      cities: [],
      hasAccessReports: false,
      showModalPlanoAvancado: false,
      ptBR,
      formData: {
        is_concordo_isencao_easy: false,
        nm_rz_social: '',
        nm_fantasia: '',
        cd_cnpj: '',
        nm_endereco_logradouro: '',
        cd_endereco_numero: '',
        nm_endereco_complemento: '',
        nm_endereco_bairro: '',
        nm_endereco_cidade: '',
        cd_endereco_cep: '',
        nm_site: '',
        ds_servicos: '',
        ds_email_contato: '',
        dt_entrada_vigor: '',
        is_servico_website: false,
        is_servico_ecommerce: false,
        is_servico_sass_sub: false,
        is_servico_app: false,
        is_servico_outro: false,
        ds_servico_outro: '',
        is_vendido_produtos: false,
        is_aceito_pagamento: false,
        is_aceito_cartao_debito: false,
        is_aceito_cartao_credito: false,
        is_aceito_boleto: false,
        is_aceito_picpay: false,
        is_aceito_paypal: false,
        is_aceito_outros: false,
        ds_outros_pagamentos: '',
        is_permitido_criar_conta: false,
        is_usuario_notificado_att: false,
        is_permitido_vincular_conta: false,
        is_foro: false,
        li_foro_cidades: [],
        is_permitido_avaliacoes: false,
        is_atividades_proibidas: false,
        is_proibido_alterar_violar: false,
        is_proibido_promover_cortes_comunicacao: false,
        is_proibido_monitorar_transimitir_virus: false,
        is_proibido_induzir_error: false,
        is_proibido_impedir_acesso_site: false,
        is_proibido_outros: false,
        ds_proibido_outros: '',
        is_nova_clausula: false,
        nm_nova_clausula: '',
        ds_nova_clausula: '',
      },
    };
  },
  watch: {
    async 'formData.cd_endereco_cep'() {
      const { cd_endereco_cep } = this.formData;

      if (cd_endereco_cep.length === 8) {
        let response = await AddressService.getAddress(cd_endereco_cep);

        if (!response.erro) {
          this.formData.nm_endereco_cidade = response.localidade;
          this.formData.nm_endereco_logradouro = response.logradouro;
          this.formData.nm_endereco_bairro = response.bairro;

          if (!this.formData.nm_endereco_complemento) {
            this.formData.nm_endereco_complemento = response.complemento;
          }
        }
      }
    },
  },
  created() {
    this.formData = { ...this.formData, ...this.document.ds_json_props };

    this.getAllCities();
    this.getAllDocuments({ id_user: this.user.id });
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['user', 'loading']),
    ...mapState('document', ['documents', 'document']),
  },
  methods: {
    ...mapActions('modal', ['toggleModalLogin', 'toggleIsCreatingDocument']),
    ...mapActions('document', ['registerDocument', 'getAllDocuments']),
    ...mapActions('alert', ['success', 'info', 'error']),
    handleSelectPlan(planId) {
      stripeService.redirectToCheckoutEasy(planId);
    },
    verifyAccessOnDocuments() {
      this.documents.map((document) => {
        if (document.document === 'relatorio-impacto.xlsm') {
          if (document.has_access) {
            this.hasAccessReports = true;
          }
        }
      });
    },
    getAllCities() {
      AddressService.getCities().then((res) => {
        let cities = res.map((citie) => {
          let uf = citie['regiao-imediata']['regiao-intermediaria'].UF.sigla;

          return `${citie.nome} - ${uf}`;
        });

        this.cities = cities;
      });
    },
    validateFormTerms() {
      if (!this.formData.nm_rz_social) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o nome da empresa',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.nm_fantasia) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o nome fantasia da empresa',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.cd_cnpj) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o CNPJ',
        });
        this.showAlert = false;

        return;
      } else {
        if (!this.validateCNPJ(this.formData.cd_cnpj)) {
          this.error({
            showType: 'toast',
            text: 'Insira um CNPJ válido',
          });
          this.showAlert = false;

          return;
        }
      }

      if (!this.formData.cd_endereco_cep) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o CEP',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.nm_site) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o nome do sistema',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.ds_email_contato) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher um e-mail para contato',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.ds_servicos) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher quais são os serviços prestados',
        });
        this.showAlert = false;

        return;
      }

      if (!this.formData.is_concordo_isencao_easy) {
        this.error({
          showType: 'toast',
          text:
            'Você precisa concordar com a isenção de responsabilidade da EasyTermos',
        });
        this.showAlert = false;

        return;
      }

      this.formData.dt_entrada_vigor = new Date();

      this.submitForm();
    },
    submitForm() {
      this.$emit('submit-form', this.formData);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tags {
  min-height: 60px;
  max-width: 885px;
}

.multiselect__tag {
  background: #4c51bf;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background: #4c51bf;
}

.multiselect__element:hover {
  background: #4c51bf;
}

.multiselect__option--highlight {
  background: #4c51bf;
}

.multiselect__option--highlight:after {
  background: #4c51bf;
}

.multiselect {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.multiselect__content-wrapper {
  position: relative;
  padding: 0;
  min-height: auto;
  max-height: none;
}

.multiselect__content {
  width: 100%;
}

.multiselect__input {
  padding: 0px !important;
  border: none !important;
  height: 0 !important;
}
</style>
